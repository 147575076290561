@import "../../variables"

.form-not-found-message
  display: flex
  flex-direction: column
  position: relative
  min-height: 465px

  &__background-image
    height: 410px
    width: auto

  .dialog-image
    height: 46px
    min-height: 46px !important
    top: -11%
    width: 46px

  .dialog
    left: 0
    margin: 0 auto
    padding: 54px 0px 15px
    position: absolute
    right: 0
    top: 90px
    max-width: 100%

  .dialog-header
    &__title
      margin-bottom: 18px

  .dialog--warning:before
    margin: 2px 8px 0px

  @media screen and (max-width: $max-width-breakpoint)
    img[alt="Form not found"]
      max-height: 465px
