.social-network-field
  display: flex
  gap: 5px

  &__image
    height: 24px
    width: 24px

    &--disabled
      filter: brightness(0.1)
      opacity: 0.2
