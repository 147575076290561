@import "@smartsuite/react-ui/lib/style-utils/variables"

.tags-field-select
  display: flex
  width: 100%
  height: auto
  padding: 0 15px

  &__empty
    text-align: center
    width: 100%

  &__pill
    cursor: pointer
    color: $typo-primary
    margin: 5px 5px

    &:not(.is-selected)
      &:hover
        color: $solution-primary-color
        background-color: $solution-secondary-color

  &__add-button
    position: absolute
    left: 10px
    top: 9px