@import "@smartsuite/react-ui/lib/style-utils/variables"
@import "../../../../variables"

.address-field-control
  &__fields
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 10px 12px

    @media screen and (max-width: $max-width-breakpoint)
      grid-template-columns: 1fr

  &__field
    flex: 1 1 50%

    &--full-width
      flex: 1 1 100%

    .plain-form-control
      margin: 0
