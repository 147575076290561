@import "../../variables"

.page-layout
  height: 100vh

  &__wrapper
    max-width: 710px
    width: 100%
    margin: 0 auto
    padding: 30px 0

  &__scrollbar
    height: 100%

@media screen and (max-width: $max-width-breakpoint)
  .page-layout
    max-width: initial
    padding: 0
