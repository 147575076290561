#eH3YkzWRNpc4
  animation: eH3YkzWRNpc4__sz 2000ms linear infinite normal forwards

@keyframes eH3YkzWRNpc4__sz
  0%
    width: 140px
    height: 20px
    animation-timing-function: cubic-bezier(0.42,0,0.58,1)
  40%
    width: 83.034478px
    height: 20px
    animation-timing-function: cubic-bezier(0.42,0,0.58,1)
  100%
    width: 139.996131px
    height: 20px

#eH3YkzWRNpc11
  animation: eH3YkzWRNpc11__sz 2000ms linear infinite normal forwards

@keyframes eH3YkzWRNpc11__sz
  0%
    width: 140px
    height: 20px
    animation-timing-function: cubic-bezier(0.42,0,0.58,1)
  60%
    width: 216.959351px
    height: 20px
    animation-timing-function: cubic-bezier(0.42,0,0.58,1)
  100%
    width: 140.002061px
    height: 20px

#eH3YkzWRNpc18
  animation: eH3YkzWRNpc18__sz 2000ms linear infinite normal forwards

@keyframes eH3YkzWRNpc18__sz
  0%
    width: 140px
    height: 20px
    animation-timing-function: cubic-bezier(0.42,0,0.58,1)
  50%
    width: 56.578807px
    height: 20px
  100%
    width: 139.997185px
    height: 20px

#eH3YkzWRNpc25
  animation: eH3YkzWRNpc25__sz 2000ms linear infinite normal forwards

@keyframes eH3YkzWRNpc25__sz
  0%
    width: 140px
    height: 20px
    animation-timing-function: cubic-bezier(0.42,0,0.58,1)
  35%
    width: 73.032758px
    height: 20px
  100%
    width: 140.003798px
    height: 20px

.select-record-preloader
  padding: 20px 30px
  font-size: 0
  // height: 389px