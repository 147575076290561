.autocomplete-address-field
  &__dropdown
    .menu-option-wrapper__list
      position: relative
      padding-bottom: 20px

      &::after
        background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png)
        background-position: right
        background-repeat: no-repeat
        background-size: 120px 14px
        bottom: 0
        content: ""
        height: 18px
        position: absolute
        right: 0
        width: 100%

