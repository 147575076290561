@import "@smartsuite/react-ui/lib/styles"
@import "@smartsuite/smartdoc/lib/esm/styles"
@import "@smartsuite/flash-notifications/lib/flash-notifications/styles.sass"

@import "./variables"
@import "./components/styles"

body
  background: #F2F7FE

@media screen and (max-width: $max-width-breakpoint)
  body
    background: #FFFFFF
