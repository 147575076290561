@import "../../variables"

.progress-restore-tooltip
  position: absolute
  top: 10px
  right: 16px
  display: flex
  align-items: center

  @media screen and (max-width: $max-width-breakpoint)
    display: none

  &__icon
    width: 28px
    height: 28px
    border-radius: 70px
    margin-left: 10px
    padding: 6px
    overflow: hidden

    &::before
      content: ""
      position: absolute
      top: 0
      right: 0
      width: 28px
      height: 28px
      border-radius: 70px
      background-color: #3A86FF
      opacity: 0.2
      z-index: 1

    svg
      z-index: 2

.progress-restore-tooltip-content
  padding: 20px 22px 24px
  display: flex
  flex-direction: column
  row-gap: 12px

  &__note
    opacity: 0.6