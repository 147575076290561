@import "@smartsuite/react-ui/lib/style-utils/mixins"
@import "@smartsuite/react-ui/lib/style-utils/variables"

.PhoneInput
  margin-left: 10px
  width: 100%

  .PhoneInputInput
    @include FontStack()
    font-size: 13px

  input::placeholder
    color: $inactive