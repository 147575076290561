@import "@smartsuite/react-ui/lib/style-utils/variables"

.form-section-item
  margin: 0 -20px 22px
  padding: 20px
  border: solid 1px $G-1-5
  border-radius: 8px

  &__label
    display: flex
    align-items: center
    margin-bottom: 2px

    div.title
      line-height: 22px

  &__items-wrapper
    margin-top: 13px
    display: flex
    flex-direction: column
    row-gap: 13px

    .form-field-item,
    .form-html-block-item,
    .form-callout-item,
    .form-video-item,
    .form-image-item,
    .divider-item,
    .recaptcha-item,
    .form-consent-item
      margin-bottom: 0

    .form-html-bloчck-item .ProseMirror *:last-child
      margin-bottom: 0
