@import "@smartsuite/react-ui/lib/style-utils/variables"

@import "../../variables"

.page-content-box
  background: $G-0
  border-radius: 12px
  box-shadow: 0 0 0 10px #E0ECFE
  margin: 10px

  &__logo
    height: 110px
    display: flex
    align-items: center
    justify-content: center
    border-bottom: 2px solid #F3F7FE

    a
      img
        max-width: 170px
        max-height: 90px

  &__body
    padding: 24px 40px

@media screen and (max-width: $max-width-breakpoint)
  .page-content-box
    box-shadow: none
    margin: 0
