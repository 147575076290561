@import "@smartsuite/react-ui/lib/style-utils/variables"
@import "@smartsuite/react-ui/lib/style-utils/mixins"
@import "src/variables"

.record-item
  $this: &

  position: relative
  height: 76px
  padding: 12px 15px 7px
  margin-left: 30px
  margin-right: 30px

  border-radius: $radius-basic
  background-color: $G-0

  @media(max-width: $max-width-breakpoint)
    margin-left: 20px
    margin-right: 20px

  @include easyTransition('out', color)

  &:not(:last-child)
    margin-bottom: 8px

  &::before
    display: block
    content: ''
    width: 100%
    height: 100%
    position: absolute
    left: 0
    top: 0
    z-index: 0
    border-radius: $radius-basic

  &::before
    box-shadow: inset 0 0 0 2px $_G-1-5
    @include easyTransition('in', box-shadow)

    pointer-events: none

  &:hover,
  &:focus:not(#{$this}--settings)
    cursor: pointer
    @include easyTransition('out', color)

    &::before
      box-shadow: inset 0 0 0 2px $solution-primary-color
      @include easyTransition('out', box-shadow)

  .user-entry__avatar
    margin-right: 4px

  &__selected-icon
    position: absolute
    color: $link
    left: -23px
    top: 50%
    margin-top: -10px

  &__title
    margin-bottom: 12px

  &__corner
    width: 32px
    height: 32px
    background: $solution-primary-color
    position: absolute
    right: -2px
    top: -2px
    border-radius: 5px
    overflow: hidden
    transform: translate3d(32px, -32px, 0px)
    transition: transform 300ms ease-in

  &__triangle
    position: absolute
    bottom: 0
    left: 0
    width: 0
    height: 0
    border-bottom: 27px solid $G-1-5
    border-right: 27px solid transparent
    transition: border-width 300ms ease-in

  &__icons
    position: absolute
    top: 0px
    right: 15px
    z-index: 1
    color: $G-0
    opacity: 0
    @include easyTransition('in', opacity)

    &::before,
    &::after
      display: block
      content: ''
      position: absolute
      background: $G-0

    &::before
      width: 2px
      height: 8px
      left: 7px
      top: 3px
      transform: rotate(0deg)

    &::after
      width: 8px
      height: 2px
      left: 4px
      top: 6px
      transform: rotate(0deg)

  &__body
    display: flex
    align-items: flex-end
    position: relative
    z-index: 1

  &--settings
    overflow: hidden
    cursor: default

    // stop events in Settings modal
    .link-field-control,
    .phone-field-control,
    .email-field-control,
    .linked-record-field-control
      pointer-events: none

  &--select
    height: 72px
    border: 2px solid
    border-color: $G-1-5
    padding-top: 6px
    @include easyTransition('in', border-color)

    &:focus
      color: $typo-primary

    &:hover,
    &.is-focused
      color: $typo-primary
      border-color: $solution-primary-color
      @include easyTransition('out', border-color)

      #{$this}__corner
        transform: translate3d(4px, -4px, 0px)
        @include easyTransition('out', transform)

      #{$this}__icons
        opacity: 0.9
        @include easyTransition('out', opacity)

    &::before, &::after
      display: none

    #{$this}__title
      padding-right: 30px

  &.is-uncolored
    &:hover

      border-color: $G-1-5
      @include easyTransition('in', border-color)

      #{$this}__corner
        transform: translate3d(32px, -32px, 0px)
        transition: transform 300ms ease-in

      #{$this}__icons
        opacity: 0
        @include easyTransition('in', opacity)

  &.is-selected:not(.is-focused)
    border-color: $solution-primary-color

    #{$this}__corner
      transform: translate3d(0px, 0px, 0px)
      @include easyTransition('out', transform)

    #{$this}__triangle
      border-width: 32px
      @include easyTransition('out', border-width)

    #{$this}__icons
      opacity: 1

      &::before
        height: 5px
        left: 4px
        top: 8px
        transform: rotate(-45deg)

      &::after
        top: 8px
        width: 10px
        transform: rotate(-45deg)

  &.is-focused.is-selected
    #{$this}__icons:before
      display: none


  &__empty-field
    height: 24px
    display: flex
    min-width: 170px
    max-width: 170px
    align-items: center

    &:not(:last-child)
      margin-right: 5px

  &__field
    overflow: hidden
    margin-right: 5px
    pointer-events: none
    width: auto
    min-width: 170px
    max-width: 170px
    pointer-events: none
    height: 24px
    display: flex
    align-items: center

    .is-left-alignment,
    .is-right-alignment,
    .is-centered-alignment
      width: 100%

  &__line
    width: 18px
    height: 2px
    background-color: $readability
    opacity: 0.21

  &__corner-wrapper
    position: absolute
    right: 0
    top: 0
    overflow: hidden
    width: 32px
    height: 32px