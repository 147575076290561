.divider-item
  display: flex
  align-items: center
  text-align: center
  width: 100%
  margin-bottom: 22px

  &__divider
    flex: 1
    border-bottom: 1px solid #000
    min-width: 40px

  &__with-text
    gap: 10px

.divider-item__text
  display: inline-block
  white-space: pre-wrap