.form-field-control
  color: $typo-primary

  &--state-error &__label
    color: $error

  &--state-active &__label
    color: $solution-primary-color

  &__label
    display: flex
    align-items: center
    margin-bottom: 6px

  &__caption
    margin-top: -3px
    margin-bottom: 6px

  &__required
    text-transform: uppercase
    margin: 3px 0 0 4px
    padding-left: 7px
    position: relative

    &::before
      content: ""
      position: absolute
      top: 50%
      margin-top: -1px
      height: 3px
      width: 3px
      border-radius: 50%
      left: 0
      background-color: #FF5757

  &__lock 
    margin-left: 4px

  &--read-only
    pointer-events: none

    input, textarea, select, .react-input-group--white
      background-color: #F3F3F4
      border-color: #F3F3F4 !important
      cursor: not-allowed

    .select-placeholder__actions .select-placeholder__arrow 
      display: none
    

  // Ovverides for rendering the char limit count in the text field, if they have a
  // 'max_length' set
  &__body
    position: relative

    .react-input-field
      overflow: inherit

      &__length
        top: calc(100% + 2px)

    .text-area-field-control--accelerator
      position: absolute
      right: 0
      top: calc(100% + 2px)

  &__error-message
    margin-top: 4px
