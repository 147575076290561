@import "@smartsuite/react-ui/lib/style-utils/variables"
@import "../../../../variables"

.full-name-field-control
  &__fields
    display: flex
    gap: 10px 12px

    @media screen and (max-width: $max-width-breakpoint)
      flex-direction: column

  &__field
    flex: 2

    &--title
      flex: 1

      & select:disabled
        appearance: none

    .plain-form-control
      margin: 0
