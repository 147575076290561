.color-picker-field-control
  width: 100%
  display: flex
  flex-wrap: wrap
  padding: 0 10px
  align-items: center

  &__clear
    position: absolute
    right: 8px
    top: 50%
    transform: translateY(-50%)

  &__single-input
    width: 100%