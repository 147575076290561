@import "src/variables"

.record-list
  display: flex
  flex-direction: column

  height: 100%
  width: 100%
  padding-top: 20px
  min-height: 339px
  max-height: var(--record-list-max-height, calc(100vh - 221px))

  &__header
    display: flex
    align-items: center
    margin-left: 30px
    margin-right: 30px
    margin-bottom: 8px
    padding: 0 15px

    @media screen and (max-width: $max-width-breakpoint)
      margin-left: 20px
      margin-right: 20px

  &__scrollbar
    width: 100%
    flex-grow: 1

    .ScrollbarsCustom
      height: 100% !important

  &__scrollbar-body
    position: relative

  &__title
    margin-right: 5px
    min-width: 170px
    max-width: 170px

  &__wrapper
    height: 100%
    display: flex
    flex-direction: column
