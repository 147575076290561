@import "@smartsuite/react-ui/lib/style-utils/variables"

.form
  &__footer
    display: flex
    justify-content: center

  &__unexpected-error
    text-align: center

.form-field-item
  margin-bottom: 22px

  .plain-form-control
    --active-color: #{$b-blue}
