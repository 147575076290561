@import "@smartsuite/react-ui/lib/style-utils/variables"

.email-field-control

  &__multiple
    width: 100%
    display: flex
    flex-wrap: wrap
    padding: 4px 0 0 5px

  &__input-multiple
    height: 26px
    width: 100%
    padding: 0 5px
    font-family: inherit
    background-color: transparent

  &__input-multiple::placeholder
    color: $inactive
    font-family: inherit
    font-size: 13px

    &__not_empty
      margin: 10px 0