@import "@smartsuite/react-ui/lib/style-utils/variables"
@import "../../variables"

.progress-restore-banner
  display: none

  flex-direction: column
  row-gap: 12px
  padding: 10px 15px
  border-radius: 10px
  background-color: $G-1
  margin: 32px -8px 0

  @media screen and (max-width: $max-width-breakpoint)
    display: flex

  &__header
    display: flex
    align-items: center
    column-gap: 12px