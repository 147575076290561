.form-submitted-message
  text-align: center
  display: flex
  align-items: center
  flex-direction: column

  &__img-container
    margin-bottom: 8px
    margin-top: 6px

  &__submit-another
    cursor: pointer

  &__subtitle
    margin-bottom: 58px
    width: 100%

  &__retry-button
    margin-bottom: 26px

  .title
    margin-bottom: 8px
