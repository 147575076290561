@import "@smartsuite/react-ui/lib/style-utils/variables"
@import "src/variables"

.select-linked-record-modal
  width: 800px
  max-height: 100vh
  min-height: 500px
  background-color: white
  box-shadow: $sh-4
  --screen-height: 100vh

  @supports (height: 100dvh)
    --screen-height: 100dvh

  @media screen and (max-width: $max-width-breakpoint)
    width: 97%
    max-width: 97%
    min-width: 97%
    height: 97vh
    height: 97dvh

  .modal__body
    max-height: calc(var(--screen-height) - 184px)

  .modal__hat,
  .modal__header
    top: 16px

  .modal__header
    margin-bottom: 16px

  &__cancel
    margin-right: 15px

  &__add-wrapper
    padding: 9px 0
    margin: 0 30px
    border-top: 1px solid $G-1-5

  &__add
    width: 100%
    justify-content: center

  &__loader, &__no-found
    height: 100%

  &.no-footer
    --record-list-max-height: calc(var(--screen-height) - 164px)

    .modal__body
      border-radius: 0 0 $radius-basic $radius-basic
      max-height: calc(var(--screen-height) - 129px)
