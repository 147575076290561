.signature-field-control
  &__text-input
    height: 64px
    width: 100%
    text-align: center
    font-family: "adobe-handwriting-ernie", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif
    font-size: 24px

  &__wrapper
    width: 100%
    line-height: 0

    &::before
      content: ""
      position: absolute
      left: 0
      top: 0
      bottom: 0
      right: 0
      background-image: url(/assets/images/diagonal-pattern.svg)
      background-size: 6px
      opacity: 0.15
      pointer-events: none

  &__footer
    margin-top: 5px
    display: flex
    justify-content: space-between
    align-items: center

  &__clear
    font-size: 12px
