.image-item
  display: flex
  margin-bottom: 22px

  &.align-left
    justify-content: flex-start

  &.align-center
    justify-content: center

  &.align-right
    justify-content: flex-end

  &__image
    border-radius: 6px
    max-width: 100%

    &.align-cover
      width: 100%
      max-height: 100%
      object-fit: cover