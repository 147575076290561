.linked-record-control

  &__input
    width: 100%
    display: flex
    flex-wrap: wrap
    min-height: 35px
    gap: 4px
    padding: 5px 25px 5px 10px
    align-items: center

  &__icon
    position: absolute
    right: 8px

.placeholder
  margin-left: 5px

.linkedrecord-multple-field-control-option
  padding: 9px 10px

  color: var(--select-option-active-color-text-type)

.linkedrecord-multiple-field-control-list
  --select-items-checkbox-margin: 9px auto 0 10px

.linkedrecord-single-field-control-option
  padding: 9px 10px

  color: var(--select-option-active-color-text-type)
