@import "@smartsuite/react-ui/lib/style-utils/variables"
@import "@smartsuite/react-ui/lib/style-utils/mixins"
@import "src/variables"

.select-linked-records-header
  $this: &

  background-color: $G-0
  padding: 0 30px
  padding-bottom: 4px
  display: flex
  align-items: center
  gap: 10px
  min-height: 37px
  flex-wrap: wrap

  @media screen and (max-width: $max-width-breakpoint)
    padding: 0 20px

  &__name
    margin-right: 15px
    margin-left: 15px
    padding-top: 7px

    strong
      font-weight: 600

  &__search
    input
      padding-right: 10px

  &__controls
    width: 100%
    display: flex
    background-color: $solution-secondary-color
    min-height: 40px
    align-items: center
    padding: 0 10px 0 20px
    border-radius: $radius-basic

  &__main
    flex-grow: 1
    position: relative

  &__settings
    margin-left: auto

    &-button
      min-width: 24px
      height: 24px
      width: 24px
      border-radius: $radius-basic
      color: $ic-dark
      background-color: $G-1
      padding: 4px

      &:hover
        color: $solution-primary-color

  &__reports
    margin-left: auto
    display: flex
    align-items: center
    gap: 10px

  &__button
    --report-button-icon-color: #{$solution-primary-color}
    --report-button-icon-opacity: 0.5

    &:after
      content: ''
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      border: 1px solid $solution-primary-color
      opacity: 0.15
      border-radius: 4px

    &:hover
      --report-button-icon-opacity: 1

      &:before
        background-color: $G-0
        @include easyTransition('out', background-color, opacity)

    &.is-active
      --report-button-icon-opacity: 1